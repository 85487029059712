'use client';

import { TypographyV3 } from '@jouzen/ecom-components';
import { useTranslations } from 'next-intl';

import Image from '@/app/components/Image';

export const Content = (): JSX.Element => {
  const t = useTranslations();

  return (
    <div className="relative bg-gray-500 pb-96 pt-64 md:pb-96 md:pt-36">
      <Image
        src="404/desktop_bg"
        alt=""
        fill
        format="png"
        priority
        data-cy="hero-background-image"
        sizes="100vw"
        style={{ objectFit: 'cover' }}
      />
      <div className="relative mx-6 flex justify-center xl:mx-auto">
        <div className="max-w-2xl text-center md:flex md:items-center md:text-left">
          <TypographyV3
            variant="h1"
            Element="h1"
            color="light"
            align="centerMdLeft"
            className="mb-4 md:mb-0 md:mr-12"
          >
            404
          </TypographyV3>
          <div>
            <TypographyV3 variant="h4" color="light" align="centerMdLeft">
              {t('404_caption')}
            </TypographyV3>
            <TypographyV3 color="light" className="mt-4" align="centerMdLeft">
              {t.rich('404_back_to_home_new')}
            </TypographyV3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
